<section class="section banner">
	<div [@bannerTrigger] class="container">
		<div class="section-box-banner">
			<div class="content">
				<div>
					<h1>Hello! My name is</h1>
				</div>
				<div class="banner-title">
					<h2>Suman Osti.</h2>
					<h3>I build digital platforms.</h3>
				</div>
				<div class="banner-description">
					<p [innerHTML]="'Banner.Description' | translate" class="mt-4"></p>
				</div>
			</div>
			<div class="div-btn-banner">
				<a
					href="mailto:suman.osti401@gmail.com"
					target="_blank"
					class="main-btn"
				>
					{{ "Banner.ActionBtn" | translate }}
				</a>
			</div>
		</div>
	</div>
</section>
