<footer>
	<div [@animateFooter]>
		<ul class="footer-left-bar d-none d-md-block">
			<li>
				<a
					href="https://github.com/metalhead224"
					target="_blank"
				>
					<i class="fab fa-github"></i>
				</a>
			</li>
			<li>
				<a
					href="https://www.linkedin.com/in/suman-osti-811a8620a/"
					target="_blank"
				>
					<i class="fab fa-linkedin-in"></i>
				</a>
			</li>
		</ul>
		<div class="footer-right-bar d-none d-md-block">
			<a
				href="mailto:suman.osti401@gmail.com"
				target="_blank"
				>suman.osti401@gmail.com
			</a>
		</div>
	</div>

	<div class="footer-credits text-center">
		<a
			href="https://github.com/metalhead224"
			target="_blank"
			rel="nofollow noopener noreferrer"
		>
			<div>Built with Angular by Suman Osti</div>
		</a>
		<a
			href="https://metalhead224.github.io/"
			target="_blank"
			rel="nofollow noopener noreferrer"
		>
			<div class="mt-2">Designed by Brittany Chiang & Jośe Andreś</div>
		</a>
	</div>
</footer>
