<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]="this.pageYPosition > 0">
	<div class="header-container">
		<div class="logo fade-enter-done" tabindex="-1">
			<a href="/" aria-label="home">
				<div class="hex-container">
					<!-- <svg
						id="hex"
						xmlns="http://www.w3.org/2000/svg"
						role="img"
						viewBox="0 0 84 96"
					>
						<title>Hexagon</title>
						<g transform="translate(-8.000000, -2.000000)">
							<g transform="translate(11.000000, 5.000000)">
								<polygon
									stroke="currentColor"
									stroke-width="5"
									stroke-linecap="round"
									stroke-linejoin="round"
									points="39 0 0 22 0 67 39 90 78 68 78 23"
									fill="currentColor"
								></polygon>
							</g>
						</g>
					</svg> -->
				</div>
				<div class="logo-container">
					<svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
						<title>Logo</title>
						<g transform="translate(-8.000000, -2.000000)">
							<g transform="translate(11.000000, 5.000000)">
								<polygon id="Shape" stroke="currentColor" stroke-width="5" stroke-linecap="round"
									stroke-linejoin="round" points="39 0 0 22 0 67 39 90 78 68 78 23"></polygon>
								<path
									d="M38,28 L33,28 L33,33 L33,44 L47,44 L47,52 L33,52 L33,57 L53,57 L53,44 L53,40 L40,40 L40,33 L53,33 L53,28 Z"
									stroke="currentColor" stroke-width="5" stroke-linecap="round"
									stroke-linejoin="round" fill="currentColor"></path>

							</g>
						</g>
					</svg>
				</div>
			</a>
		</div>
		<ul ngbNav #nav="ngbNav" class="menu-ul">
			<li ngbNavItem>
				<a ngbNavLink (click)="scroll('about')"><span class="nav-number">01. </span>
					<span class="underline nav-text">
						{{ "Header.Item1" | translate }}</span></a>
				<ng-template ngbNavContent>First content</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink (click)="scroll('jobs')"><span class="nav-number">02. </span>
					<span class="underline nav-text">
						{{ "Header.Item2" | translate }}</span></a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink (click)="scroll('projects')"><span class="nav-number">03. </span>
					<span class="underline nav-text">
						{{ "Header.Item3" | translate }}</span></a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink (click)="scroll('testimonial')"><span class="nav-number">04. </span>
					<span class="underline nav-text">
						{{ "Header.Item4" | translate }}</span></a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink (click)="scroll('contact')"><span class="nav-number">05. </span>
					<span class="underline nav-text">
						{{ "Header.Item5" | translate }}</span></a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li ngbNavItem>
				<a (click)="downloadCV()" class="main-btn cv-btn" ngbNavLink>
					{{ "Header.cvBtn" | translate }}
				</a>
			</li>
		</ul>
		<div class="menu-wrapper">
			<div [class.animate]="responsiveMenuVisible" (click)="responsiveMenuVisible = !responsiveMenuVisible"
				class="hamburger-menu"></div>
		</div>
		<div class="menu-responsive" [ngStyle]="{ 'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
			<aside [class.aside-show]="responsiveMenuVisible" [class.nav-shadow]="this.pageYPosition > 0"
				class="on-top">
				<nav>
					<ol>
						<li>
							<a (click)="scroll('about')">
								<span>01. </span> {{ "Header.Item1" | translate }}
							</a>
						</li>
						<li>
							<a (click)="scroll('jobs')">
								<span>02. </span> {{ "Header.Item2" | translate }}
							</a>
						</li>
						<li>
							<a (click)="scroll('projects')">
								<span>03. </span> {{ "Header.Item3" | translate }}
							</a>
						</li>
						<li>
							<a (click)="scroll('projects')">
								<span>04. </span> {{ "Header.Item4" | translate }}
							</a>
						</li>
						<li>
							<a (click)="downloadCV()" class="main-btn cv-btn">
								{{ "Header.cvBtn" | translate }}
							</a>
						</li>

						<!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
					</ol>
				</nav>
			</aside>
		</div>
	</div>
</nav>